import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Merge Sort With Linked List | Code Tutorial'}
			subHeader={''}
			description={'A no-bs line-by-line code explanation of implementing the Mergesort with a Linked List in pure C.'}
			keywords={[]}
			embeddedVideo={'jOyYaBHyN28'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/4b3d369b1d93f4543ac447df0ba7b22f/raw/e21c010b604de309cb02b8cbe356f5027c08bcb9/gistfile1.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
